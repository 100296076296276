<template>
  <VList :headers="headers" :rows="displayedData" :is-loading="isLoading">
    <template #title>
      <div class="flex text-base justify-center font-medium">
        {{ $t("app.expiring_competencies", 2) }}
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage
        :color="item.competency?.colour"
        :name="item.displayed_competency"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.competency="{ item }">
      <div class="font-medium whitespace-nowrap">
        {{ item.displayed_competency }}
      </div>

      <VLine class="font-light text-gray-600 text-xs whitespace-nowrap mt-0.5">
        <template #label>
          <div class="space">
            {{ $t("app.obtained_via") }}
          </div>
        </template>

        <template #value>
          <div class="font-medium">
            {{ item.displayed_source }}
          </div>
        </template>
      </VLine>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
import useTexts from "@/composables/useTexts";
import useReadMultiple from "@/composables/useReadMultiple";
// Components
import VList from "@/components/tables/VList";
import VChip from "@/components/VChip";
import VLine from "@/components/VLine";
import VImage from "@/components/VImage";

export default {
  components: {
    VList,
    VChip,
    VImage,
    VLine
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Composables
    const { isLoading, data, read } = useReadMultiple();
    const { getStatusColor } = useColor();
    const { getText } = useTexts();
    const { formatDate } = useDisplay();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.competencies"),
        value: "competency"
      },
      {
        text: t("app.obtained_on"),
        value: "displayed_obtained_on"
      },
      {
        text: t("app.expires_on"),
        value: "displayed_expires_on"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      }
    ];

    // Computed
    const displayedData = computed(() => {
      if (!data.value.length) {
        return [];
      }

      return data.value?.map(x => ({
        ...x,
        displayed_competency: getText(x?.competency?.texts, "name"),
        displayed_source: getText(x?.source?.texts, "name"),
        displayed_status: t(`app.${x?.status}`),
        displayed_expires_on: formatDate({ date: x?.expires_on }),
        displayed_obtained_on: formatDate({ date: x?.obtained_on })
      }));
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: "learning.competencies.expiring"
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      headers,
      displayedData,
      // useReadMultiple
      isLoading,
      // useColor
      getStatusColor
    };
  }
};
</script>
