<template>
  <div>
    <VTitle class="flex justify-baseline items-center route-title">
      {{ $t("app.competencies", 2) }}

      <VHint class="ml-4 text-base">
        {{ $t("hints.learning_competencies") }}
      </VHint>
    </VTitle>

    <div class="grid grid-cols-5 gap-8">
      <div class="col-span-5 xl:col-span-2">
        <div v-if="isLoading" class="flex justify-center">
          <LoadingIcon icon="three-dots" class="w-8 h-8" />
        </div>

        <VStatisticsCard
          v-else
          :title="$t('app.statuses', 2)"
          :statistics="statuses"
        />

        <ExpiringCompetencies />
      </div>

      <CompetenciesOverview class="col-span-5 xl:col-span-3" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import CompetenciesOverview from "./CompetenciesOverview";
import ExpiringCompetencies from "./ExpiringCompetencies";
import VStatisticsCard from "@/components/VStatisticsCard";
import VHint from "@/components/VHint";

export default {
  components: {
    VTitle,
    VHint,
    CompetenciesOverview,
    ExpiringCompetencies,
    VStatisticsCard
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();

    // Data
    const statuses = ref({});
    const isLoading = ref(false);

    // Constants
    const documentTitle = `${t("app.competencies", 2)} - ${t("app.learning")}`;

    // Methods
    const getStatuses = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "learning.competencies.statuses",
        showToaster: false
      });

      statuses.value = response?.payload?.data ?? {};
      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getStatuses();
    });

    return {
      statuses,
      isLoading,
      documentTitle
    };
  }
};
</script>
