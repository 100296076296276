<template>
  <VList
    class="-mt-5"
    :headers="headers"
    :rows="displayedData"
    :is-loading="isLoading"
    display-pagination
    :pagination="pagination"
    @update:current_page="onUpdateCurrentPage"
    @update:per_page="onUpdatePerPage"
  >
    <template #title>
      <div class="flex text-base justify-center font-medium">
        {{ competenciesTitle }}
      </div>
    </template>

    <template #title-actions>
      <VDropdown
        dropdown-menu-class="w-48"
        :selected="selectedStatus"
        :options="statusOptions"
        @click="selectedStatus = $event"
      >
        <template #option="{ option }">
          <div class="cursor-pointer p-2 flex justify-between items-center">
            <div class="truncate w-2/3">
              {{ option.text }}
            </div>

            <div
              v-if="option.value !== 'all'"
              class="rounded-full w-3 h-3"
              :style="{ 'background-color': getRGBStatusColor(option.value) }"
            />
          </div>
        </template>
      </VDropdown>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage
        :color="item.competency?.colour"
        :name="item.displayed_competency"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.competency="{ item }">
      <div class="font-medium whitespace-nowrap">
        {{ item.displayed_competency }}
      </div>

      <VLine class="font-light text-gray-600 text-xs whitespace-nowrap mt-0.5">
        <template #label>
          <div class="space">
            {{ $t("app.obtained_via") }}
          </div>
        </template>

        <template #value>
          <div class="font-medium">
            {{ item.displayed_source }}
          </div>
        </template>
      </VLine>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
import usePaginate from "@/composables/usePaginate";
import useDisplay from "@/composables/useDisplay";
import useOptions from "@/composables/useOptions";
// Components
import VList from "@/components/tables/VList";
import VChip from "@/components/VChip";
import VLine from "@/components/VLine";
import VImage from "@/components/VImage";
import VDropdown from "@/components/VDropdown";

export default {
  components: {
    VList,
    VChip,
    VImage,
    VLine,
    VDropdown
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Composables
    const { isLoading, data, read, pagination } = useReadMultiple();
    const { getStatusColor, getRGBStatusColor } = useColor();
    const { getText } = useTexts();
    const { formatDate } = useDisplay();
    const { ALL_OPTIONS } = useOptions();

    // Data
    const selectedStatus = ref("all");

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.competencies"),
        value: "competency"
      },
      {
        text: t("app.obtained_on"),
        value: "displayed_obtained_on"
      },
      {
        text: t("app.expires_on"),
        value: "displayed_expires_on"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      }
    ];
    const statusOptions = [
      {
        text: t("app.all_statuses"),
        value: "all"
      },
      ALL_OPTIONS.NON_OBTAINABLE,
      ALL_OPTIONS.OBTAINABLE,
      ALL_OPTIONS.WAITING_APPROVAL,
      ALL_OPTIONS.EXPIRED,
      ALL_OPTIONS.VALID
    ];

    // Computed
    const displayedData = computed(() => {
      if (!data.value.length) {
        return [];
      }

      return data.value?.map(x => ({
        ...x,
        displayed_competency: getText(x?.competency?.texts, "name"),
        displayed_source: getText(x?.source?.texts, "name"),
        displayed_status: t(`app.${x?.status}`),
        displayed_expires_on: formatDate({ date: x?.expires_on }),
        displayed_obtained_on: formatDate({ date: x?.obtained_on })
      }));
    });

    const competenciesTitle = computed(() => {
      const titles = {
        all: t("app.all_competencies"),
        // eslint-disable-next-line
        [ALL_OPTIONS.NON_OBTAINABLE.value]: t("app.non_obtainable_competencies"),
        [ALL_OPTIONS.OBTAINABLE.value]: t("app.obtainable_competencies"),
        // eslint-disable-next-line
        [ALL_OPTIONS.WAITING_APPROVAL.value]: t("app.waiting_approval_competencies"),
        [ALL_OPTIONS.EXPIRED.value]: t("app.expired_competencies"),
        [ALL_OPTIONS.VALID.value]: t("app.valid_competencies")
      };

      return titles[selectedStatus.value] ?? t("app.competencies", 2);
    });

    // Methods
    const getData = async () => {
      let queryParams = {};

      if (selectedStatus.value !== "all") {
        queryParams = {
          ro_f: JSON.stringify([
            {
              f: "status",
              c: "EQUAL",
              v: [selectedStatus.value]
            }
          ])
        };
      }

      await read({
        endpoint: "learning.competencies",
        queryParams
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    // Composables
    const { onUpdateCurrentPage, onUpdatePerPage } = usePaginate(
      pagination,
      getData
    );

    // Watch
    watch(selectedStatus, async () => {
      await getData();
    });

    return {
      competenciesTitle,
      selectedStatus,
      headers,
      displayedData,
      statusOptions,
      // useReadMultiple
      isLoading,
      pagination,
      // usePaginate
      onUpdatePerPage,
      onUpdateCurrentPage,
      // useColor
      getStatusColor,
      getRGBStatusColor
    };
  }
};
</script>
